import { connect } from 'react-redux';
import MemberActions from '../components/MemberActions';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  let partnershipId = storage.get('local', 'partnershipId');

  const careGuide = state.entities.getIn(['careGuidesAdmin', id]);

  return {
    careGuide,
    careGuideId: id,
    partnershipId: partnershipId,
    partnershipName: storage.get('local', 'partnershipData')?.name,
    accessCareGuide: storage.get('local', 'partnershipData')
      ?.access_role_admin_in_care_guide,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const MemberActionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberActions);

export default MemberActionsContainer;
